<template lang="pug">
  .change_password_wrapper
    h2.nar_title {{ title || 'Сменить пароль'}} 
    .content_wrapper.row
      .form_block.column
        form.login(@submit.prevent='changePASS')
          .astra_input_wrapper
            label.label_text(for='current_password') Новый пароль
            input.astra-input(v-model='new_password1' type='password' placeholder='Введите пароль')
          .astra_input_wrapper
            label.label_text(for='new_password') Повторите пароль
            input.astra-input(v-model='new_password2' type='password' placeholder='Введите пароль')
          .form_buttons_block
            button.astra_accept_button Продолжить
      .image_block.column
        img(src='~@/assets/astra-lock.png' alt='')
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      new_password1: "",
      new_password2: ""
    };
  },
  props: { title: String },

  methods: {
    changePASS: function() {
      let { dispatch } = this.$store;
      let { new_password1, new_password2 } = this;

      dispatch("users/changePassword", { new_password1, new_password2 });
    }
  }
};
</script>

<style >
@import url("~@/assets/scss/change_password.scss");
</style>
