<template>
  <div class="public_wrapper">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "password_recovery",
  methods: {}
};
</script>
