<template>
  <div id="app">
    <!-- <NarradorHelper/> -->
    <NarModal />
    <mainLoader v-if="$store.state.fetching" />
    <router-view></router-view>
  </div>
</template>

<script>
import mainLoader from "./components/Common/MainLoader";
// import NarradorHelper from "./components/Common/NarradorHelper";
import NarModal from "./components/Common/NarModal";
export default {
  name: "App",
  components: {
    mainLoader,
    NarModal,
    // NarradorHelper
  },
  methods: {
    closeModal() {
      this.$store.state.show_modal = false;
    }
  },
  mounted() {

  }
};
</script>


<style>
@import "./assets/css/normalize.css";
body {
  background-color: #f7f9ff;
}

#app {
  font-family: "Segoe UI", Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f7f9ff;
}

.nr_mobile {
  display: none;
}

button {
  font-family: "Segoe UI";
}

.personal_cabinet_wrapper {
  max-width: 1480px;
  padding: 0 20px;
  margin: 0 auto;
}

.main_wrapper {
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  padding: 50px 0;
}

.astra_logo {
  width: 320px;
}

.astra_logo img {
  width: 100%;
}

.main_wrapper .main_block {
  background-color: #fff;
  display: block;
  border-radius: 15px;
  width: 100%;
  padding: 20px 75px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.astra_button {
  border-radius: 11px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

textarea:focus,
button:focus,
input:focus,
select:focus {
  box-shadow: 0 0 3px #ffb113 !important;
  outline: none;
}

@media only screen and (max-width: 1500px) {
  .main_wrapper .main_block {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .main_wrapper{
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .main_wrapper {
    width: 100%;
  }

  .left_side_wrapper,
  .main_block.personal_cabinet {
    display: none;
  }
  .left_side_wrapper.active,
  .main_block.personal_cabinet.active {
    display: block;
  }

  .main_wrapper .main_block {
    padding: 20px 20px;
    background-color: transparent;
  }

  .nar_title{
    color: #777777;
  }

  .main_wrapper .main_block {
    box-shadow: unset;
  }

  .personal_cabinet_wrapper{
    padding: 0;
  }
}
</style>
