import { userService } from '../../_services';
import { router } from '../../router';

export const registration = {
    namespaced: true,
    state: {
        errors: []
    },
    actions: {
        registrationStep1({ commit, rootState }, { uin, mobile }) {
            rootState.fetching = true;
            commit('step1equest', { uin, mobile });

            userService.registrationStep1({ uin, mobile })
                .then(
                    res => {
                        rootState.fetching = false;

                        console.log('module success => ', res)
                        localStorage.setItem("user_mobile", mobile);

                        if (!res.status) {
                            userService.sendSMS(mobile);
                            router.push("/public/reg/step2");
                        }

                    },
                    error => {
                        rootState.fetching = false;
                        commit('step1Failure', error);

                    }
                );

        },

        registrationStep2({ commit, rootState }, { token, mobile }) {
            rootState.fetching = true;

            commit('step1equest', { token, mobile });

            userService.registrationStep2({ token, mobile })
                .then(
                    res => {
                        rootState.fetching = false;

                        localStorage.setItem("user_token", res.token);

                        router.push("/public/reg/step3/Установить пароль");

                    },
                    error => {
                        rootState.fetching = false;

                        commit('step2Failure', error);
                    }
                );
        },

        RecoveryPaswwordSendSMS({ commit, rootState }, mobile) {
            rootState.fetching = true;

            localStorage.setItem("user_mobile", mobile);
            userService.sendSMS( mobile )
                .then(
                    res => {
                        if (res)
                            router.push("/public/password-recovery/step2");
                    },
                    error => {
                        commit('step1Failure', error);

                    }
                ).finally(() => {
                    rootState.fetching = false;
                });

        },

    },
    mutations: {
        step1equest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        step1Success(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        step1Failure(state, error) {
            // state.errors = error
            if (typeof error == 'object') {
                if (Array.isArray(error))
                    state.errors = [...state.errors, ...error]
            }
            else
                state.errors.push(error)
        },


        step2equest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        step2Success(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        step2Failure(state, error) {

            if (typeof error == 'object') {
                if (Array.isArray(error))
                    state.errors = [...state.errors, ...error]
            }
            else
                state.errors.push(error)
        },


    }
}