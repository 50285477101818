import { authHeader } from '../_helpers'
import { store } from '@/store'

export const loanService = { getAll, getByKey, getPayInfo, getAllTransactions }

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API}/tickets/`, requestOptions).then(handleResponse);
}

async function getByKey(key) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...authHeader() },
    };


    return fetch(`${process.env.VUE_APP_API}/tickets/${key}/`, requestOptions).then(handleResponse);
}

function getPayInfo(key) {
    const requestOptions = {
        method: 'get',
        headers: { 'Content-Type': 'application/json; charset=utf-8', ...authHeader() },
    };


    return fetch(`${process.env.VUE_APP_API}/tickets/${key}/pay/paybox/`, requestOptions).then(handleResponse);
}

function getAllTransactions() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${process.env.VUE_APP_API}/payments/`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;

            switch (response.status) {
                case 523: {
                    console.log(response);
                    console.log('523 error', error);
                    store.commit('notes_module/showNote', {"name":'1c_error', "message":error});
                }
            }

            return Promise.reject(error);
        }
        return data;
    });
}