import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import { store } from "./store";
import { router } from "./router";
import VueSkeletonLoading from 'vue-skeleton-loading';


Vue.use(Vuelidate)
Vue.use(VueSkeletonLoading)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
