<template lang="pug">
  .register_wrapper
    .content_wrapper.row
      .form_block.column
        h2.nar_title Регистрация
        form.login(@submit.prevent='sendOTP')
          .astra_input_wrapper
            label.label_text(for='confirm_password') SMS код
            input.astra-input(v-model='token' type='text' placeholder='Введите SMS код' v-mask="'######'")
          .notification_wrapper
            | Уже регистрировались? &nbsp;
            router-link(to='/public/auth') Авторизируйтесь
          .form_buttons_block
            button.astra_accept_button Продолжить
      .image_block.column
        img(src='~@/assets/astra-lock.png' alt='')
    .errors_wrapper(v-if='errors.length > 0')
      .error_item(v-for='error in errors' :key='error') {{error}}
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";

export default {
  components: {},
  data() {
    return {
      token: "",
      mobile: localStorage.getItem("user_mobile") || "",
    };
  },
  computed: {
    errors() {
      return this.$store.state.registration.errors;
    }
  },
  validations: {
    token: { required, minLength: minLength(6) },
    mobile: { required }
  },
  directives: { mask },
  methods: {
    sendOTP: function() {
      let { dispatch } = this.$store;
      let { token, mobile } = this;

      this.$v.$touch();
      if (this.$v.$invalid) return;

      dispatch("registration/registrationStep2", { token, mobile });
    },
    sendSMS() {
    
    }
  }
};
</script>

<style >
@import url("~@/assets/scss/steps.scss");
</style>